.paragraph {
  @apply pb-6;

  & :global(.object-tag) {
    @apply pb-0;
  }

  & a {
    @apply underline;
  }

  /* Slightly more gap when paragraph is followed by theme-block */
  & + div[data-block^="theme/"] {
    @apply pt-4;
  }

  &:global(.layout-align-left) {
    @apply max-w-8xl mx-auto;
    & > * {
      @apply max-w-1xl;
    }
  }
}
